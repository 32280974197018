

.svg-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.svg {
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.3s ease-in-out;
}

.default-svg {
  opacity: 1;
  margin-top: -1rem;
}

.hover-svg {
  opacity: 0;
  height: 95%;
  margin-top: 0.8rem;
}

.svg-wrapper:hover .default-svg {
  opacity: 0;
}

.svg-wrapper:hover .hover-svg {
  opacity: 1;
}


@media screen and (max-width: 768px) {
  .svg-wrapper {
    width: 100%;
    height: 75%;
  }
  
  .default-svg {
    opacity: 0;
  }
  
  .hover-svg {
    opacity: 1;
    height: 86%;
  }
}