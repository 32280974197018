@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../common/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../common/fonts/Ibm-plex-sans/IBMPlexSans-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../common/fonts/Ibm-plex-sans/IBMPlexSans-Medium.otf")
    format("opentype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../common/fonts/Ibm-plex-sans/IBMPlexSans-SemiBold.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../common/fonts/Ibm-plex-sans/IBMPlexSans-Bold.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("../common/fonts/Roobert/Roobert.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("../common/fonts/Roobert/Roobert.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("../common/fonts/Roobert/Roobert.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("../common/fonts/Roobert/Roobert.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("../common/fonts/Roobert/Roobert.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../common/fonts/Satoshi/fonts/Satoshi-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../common/fonts/Satoshi/fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../common/fonts/Satoshi/fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  appearance: none;
}

input {
  pointer-events: auto; /* Make sure input is interactive */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

@layer components {
  .btn-primary-min {
    @apply tracking-normal py-[8px] duration-300 ease-in-out px-[32px] outline-none text-base cursor-pointer font-medium bg-[#072723] text-primary-white focus:outline-none rounded-[40px] xlsm:text-[1rem];
  }
  .primary-link {
    @apply tracking-normal no-underline text-base  text-[#2F37EF] font-medium py-2 px-4;
    transition: 0.35s ease-in-out;
    &:hover {
      @apply bg-[#EEFFF5] rounded;
      transition: 0.35s ease-in-out;
    }
  }
  .container {
    @apply lg:px-6 xl:px-12 w-full  px-14 mx-auto max-w-8xl h-full xlsm:px-3;
  }
  .max-content {
    @apply max-w-[1350px] mx-auto h-full w-full;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  80% {
    transform: translateX(86px);
    opacity: 1;
  }

  100% {
    transform: translateX(96px);
    opacity: 0.2;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(-100px);
    opacity: 0.2;
  }
  20% {
    transform: translateX(-86px);
    opacity: 1;
  }
  100% {
    transform: translateX(130px);
    opacity: 0;
  }
}

/* App */
.app-container {
  margin: 0 auto;
}

.coin-left {
  animation: slideIn 3.8s ease-in-out infinite;
}

.coin-right {
  animation: slideOut 4s ease-in-out infinite;
  opacity: 0;
  animation-delay: 2.9s;
  /* Delay for 5 seconds, after coin-left finishes */
}

.solu-bottom > .bottom-left::before {
  content: " ";
  position: absolute;
  background-image: url("/public/BrandPatternGreen.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  left: 4.7rem;
  top: 2.5rem;
  z-index: 1;
}

.sts-bottom > .bottom-left::before {
  content: " ";
  position: absolute;
  background-image: url("/public/BrandPatternOrange.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  right: 2rem;
  top: 3rem;
  z-index: 1;
}

.busi-bottom > .bottom-left::before {
  content: " ";
  position: absolute;
  background-image: url("/public/BrandPatternBlue.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  left: 4.7rem;
  top: 3rem;
  z-index: 1;
}

.busi-bottom > .bottom-left::after,
.sts-bottom > .bottom-left::after,
.solu-bottom > .bottom-left::after {
  content: " ";
  position: absolute;
  background: #f4f4f4;
  border-radius: 40px;
  width: 536px;
  height: 475px;
  background-repeat: no-repeat;
  z-index: 2;
}

.tool-cont .tooltip-text {
  opacity: 0;
  width: 330px;
  height: 135px;
  background-image: url("/public/assets/Tooltip.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 25px 30px;
  top: -8.4rem;
  visibility: hidden;
}

.tool-cont:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
}

.preFooter-bg::after {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("/public/BrandPatternFooter.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  z-index: 1;
}

.liquidity-bottom > .bottom-left::after {
  content: " ";
  position: absolute;
  background-image: url("/public/busiPattern.svg");
  border-radius: 40px;
  width: 570px;
  height: 410px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
}

.about-bg::before {
  content: " ";
  overflow: hidden;
  position: absolute;
  background-image: url("/public/aboutBrandPattern.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  left: -0.05rem;
  bottom: -10.2rem;
  z-index: 1;
}

.bullet::before {
  content: " ";
  width: 4px;
  height: 4px;
  position: absolute;
  top: 10px;
  left: -13px;

  background: #101828;
  border-radius: 50%;
}

.infinite-scroll-content {
  display: flex;
  gap: 1rem;
  animation: scroll-left 11s linear infinite; /* Adjust the duration based on speed */
}

.card-top2::before,
.card-top::before {
  content: "";
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  left: -0.05rem;
  z-index: 1;
}
.card-top::before {
  background-image: url("/public/blogBrandPattern.svg");
  bottom: -2.1rem;
}
.card-top2::before {
  background-image: url("/public/blogBrandPattern2.svg");
  bottom: -1.3rem;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-51%); /* Scroll to half the width of the content */
  }
}

/* Make sure no scrollbar appears */
.bottom-coins {
  overflow: hidden;
}

.arrow-pointer::before {
  content: " ";
  position: absolute;
  background: #eaecf0;
  width: 40px;
  height: 72px;
  z-index: -1;
  left: -0.18rem;
  top: -0.16rem;
  opacity: 0.68;
  border-radius: 55px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  animation: moveUpAndDown2 1.6s ease-in-out infinite;
}

.arrow-pointer {
  animation: moveUpAndDown 1.6s ease-in-out infinite;
}

@keyframes moveUpAndDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes moveUpAndDown2 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(1.5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.contact-bg::before {
  content: " ";
  overflow: hidden;
  position: absolute;
  background-image: url("/public/aboutBrandPattern.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  left: -0.05rem;
  bottom: -5.3rem;
  z-index: 1;
}
textarea:-webkit-autofill,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; /* or another background color */
  box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #182230; /* Reset the text color */
}

input,
textarea {
  color: #182230;
}

/* @media (min-width: 3840px) { */
@media (min-width: 1825px) {
  /* .app-container  { */
  /* max-width: 1800px; Adjust width for 4K screens */
  /* padding: 40px;     Add extra padding for large screens */
  /* } */
  .navbarr .navbarr-cont {
    max-width: 1800px;
    margin: 0 auto;
  }

  .home-wrapper {
    margin: 0 auto;
  }

  .home-container .home-hero,
  .home-container .home-solu,
  .home-container .home-global,
  .home-container .home-built,
  .home-container .home-differ,
  .home-container .home-hear,
  .home-container .home-faq,
  .home-container .home-case {
    max-width: 1800px;
    margin: 0 auto;
  }

  .about-container .business-hero,
  .about-container .vision {
    max-width: 1800px;
    margin: 0 auto;
  }

  .coming-wrapper {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }

  .contact-container .contact-hero,
  .contact-container .business-hero {
    max-width: 1800px;
    margin: 0 auto;
  }

  .yolo {
    position: relative;
    top: -67px;
  }
  .blogs-container,
  .blog-details-container .blog-details-hero,
  .blog-details-container .blog-details-content,
  .blog-details-container .home-faq,
  .useCases-container .business-hero,
  .useCases-container .cases,
  .useCases-container .home-hear,
  .useCases-container .three-steps,
  .useCases-container .home-faq,
  .payment-container .business-hero,
  .payment-container .three-steps,
  .payment-container .home-differ,
  .payment-container .home-faq,
  .business-container .business-hero,
  .business-container .busi-solu,
  .business-container .three-steps,
  .business-container .home-faq,
  .portal-container .business-hero,
  .portal-container .busi-solu,
  .portal-container .home-differ,
  .portal-container .three-steps,
  .portal-container .home-faq {
    max-width: 1800px;
    margin: 0 auto;
  }

  .blog-details-hero .topnav {
    padding-top: 70px;
  }

  .blog-details-content .details-content {
    height: 100%;
    width: 800px;
  }

  .blogs-container .blogs-wrapper {
    padding-left: 146px;
    padding-right: 146px;
  }

  .home-container .home-hero svg {
    width: 73%;
    right: 132px;
  }

  .home-container .home-solu .bottom-left img {
    width: 78%;
    margin: unset;
  }

  .home-container .home-solu .bottom-calc img {
    width: 76%;
  }

  .home-container .home-solu .bottom-left .bottom-coins {
    width: 62%;
    bottom: 3.05rem;
    left: -10.5rem;
  }

  .home-container .home-solu .bottom-busii img {
    width: 63%;
    margin-left: 3rem;
  }

  .portal-container .liquidity-bottom .bottom-left img {
    width: 78%;
  }

  .portal-container .liquidity-bottom .bottom-wallet img {
    width: 68%;
  }

  .portal-container .liquidity-bottom .bottom-curren img {
    width: 43%;
  }

  .portal-container .liquidity-bottom .bottom-hands img {
    width: 48%;
  }

  .liquidity-bottom > .bottom-left::after {
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .business-container .liquidity-bottom .bottom-left img {
    width: 43%;
  }

  .business-container .liquidity-bottom .bottom-liquid img {
    width: 76%;
  }

  .business-container .liquidity-bottom .bottom-coins {
    width: 62%;
  }

  .business-container .liquidity-bottom .bottom-gas img {
    width: 55%;
  }

  .footer .footer-cont {
    max-width: 1800px;
    margin: 0 auto;
  }

  .footer .preFooter {
    width: 84%;
  }
}

@media screen and (max-width: 768px) {
  .mobile-bar::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3.5px;
    background: #175cd3;
    top: -0.62rem;
  }
  .mobile-bar::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3.5px;
    background: #175cd3;
    bottom: -0.62rem;
  }

  .footer-texts {
    font-size: 15px;
  }

  .footer-text-top {
    font-size: 13px;
  }
  .home-text,
  .top-text,
  .solu-header,
  .global-header,
  .built-header,
  .differ-header,
  .faq-header,
  .case-header {
    font-size: 31px;
  }

  .hear-text {
    font-size: 29px;
  }

  .built-texts {
    font-size: 17px;
  }

  .tool-cont .tooltip-text {
    width: 198px;
    height: 135px;
    font-size: 10.5px;
    border-radius: 4px;
    top: -8.4rem;
    background-size: 170%;
    padding: 8px 20px 10px 25px;
  }

  .busi-bottom > .bottom-left::after,
  .sts-bottom > .bottom-left::after {
    width: 100%;
    height: 336px;
  }

  .solu-bottom > .bottom-left::after {
    width: 95%;
    height: 336px;
  }

  .solu-bottom > .bottom-left::before,
  .busi-bottom > .bottom-left::before {
    width: 60%;
    height: 70%;
    left: 4rem;
    top: 6.2rem;
    background-size: cover;
  }

  .sts-bottom > .bottom-left::before {
    width: 60%;
    height: 70%;
    right: 4.5rem;
    top: 6.1rem;
    background-size: cover;
  }

  .bottom-coins {
    padding-top: 11px;
    padding-bottom: 11px;
    bottom: 5.5rem;
    left: -1rem;
    scrollbar-width: none;
  }

  .coins-cont > svg {
    width: 28px;
  }

  .solu-bottom > .bottom-left::before {
    width: 60%;
    height: 44%;
    left: 8.7rem;
    top: 13.2rem;
    background-size: contain;
  }

  .about-bg::before {
    content: " ";
    overflow: hidden;
    position: absolute;
    background-image: url("/public/brandPatternMob1.svg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    left: -0.05rem;
    bottom: -11.2rem;
    z-index: 1;
  }

  .steps-text1 {
    font-size: 19;
  }

  .steps-text2 {
    font-size: 17;
  }

  .arrow-pointer::before {
    width: 37px;
    height: 69px;
    left: -0.15rem;
  }

  .liquidity-bottom > .bottom-left::after {
    width: 100%;
    height: 280px;
  }

  .contact-bg::before {
    content: " ";
    overflow: hidden;
    position: absolute;
    background-image: url("/public/brandPatternMob1.svg");
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    left: -0.05rem;
    bottom: -8.9rem;
    z-index: 1;
  }
}

@media screen and (max-width: 480px) {
}
